import React from 'react'
import Aboutus from '../home/Aboutus'
import Blogs from '../home/Blogs'
import Services from '../home/Services'
import Testamonial from '../home/Testamonial'
import Hero from '../shared/Hero'

export default function Home() {
    return (
        <div className="">
          <Hero/>
          <Services/>
          <Aboutus/>
          <Testamonial/>
          <Blogs/>

         
        </div>
    )
}
