import React from "react";
import Testamonialcard from "../shared/Testamonialcard";
import Title from "../shared/Title";
import Carousel from "react-bootstrap/Carousel";

export default function Testamonial() {
  return (
    <div className="container mt-5 ">
      <Title title="Testimonials" />

      <Carousel>
        <Carousel.Item> <Testamonialcard /></Carousel.Item>
        <Carousel.Item> <Testamonialcard /></Carousel.Item>
      </Carousel>
     
    </div>
  );
}
