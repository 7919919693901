import React from "react";
import Title from "../shared/Title";
import { useNavigate } from "react-router-dom";

export default function Pricing() {
  const history = useNavigate();
  return (
    <div className="pricing-main-container container">
      <Title title="pricing" />
      <section className="top-headings">
        <p>When two pictures cannot have the same Story, </p>
        <p>then how 2 Clients can have the same pricing!!!!!</p>
      </section>
      <section className="img-container mx-auto">
        <img
          src="/assets/Content/svg_pricing.svg"
          alt=""
          className="img-fluid"
        />
      </section>
      <section className="bottom-container">
        <p>
          {" "}
          As, every picture has a different Story, so at Shoot a Story we offer
          a{" "}
        </p>
        <p>Customised price package to cater to all your requirements.</p>
        <span>Trust us Your Story worth our Pricing </span>
        <button
          className="explore-more-btn"
          onClick={() => history("/contact", { replace: true })}
        >
          contact us
        </button>
      </section>
    </div>
  );
}
