import React from "react";
import { Link } from "react-router-dom";

export default function Hovercard({ title, image }) {
  return (
    <div className="hover-card-container">
      <Link to={`/photography/${title}`}>
        <>
          <img src={image} className="img-fluid" alt="shoot a story services" />
          <div className="content">
            <h4>{title}</h4>
          </div>
        </>
      </Link>
    </div>
  );
}
