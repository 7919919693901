import React from "react";
import Hoverableservicecard from "../services/Hoverableservicecard";
import Title from "../shared/Title";

export default function Services() {
  const data = [
    {
      id: 1,
      image: "/assets/Service_page/Fashion.webp",
      title: "Fashion",
    },
    {
      id: 2,
      image: "/assets/Service_page/E_Commerce.webp",
      title: "E-Commerce",
    },
    {
      id: 3,
      image: "/assets/Service_page/Portfolio.webp",
      title: "Portfolio",
    },
    {
      id: 4,
      image: "/assets/Service_page/Family.webp",
      title: "Family",
    },
    {
      id: 5,
      image: "/assets/Service_page/Food.webp",
      title: "Food and Leisure",
    },
    {
      id: 6,
      image: "/assets/Service_page/Nature.webp",
      title: "Nature",
    },
    {
      id: 7,
      image: "/assets/Service_page/Events.webp",
      title: "Events",
    },
    {
      id: 8,
      image: "/assets/Service_page/Videos.webp",
      title: "Videos",
    },
  ];
  return (
    <div className="container">
      <Title title="our services" />
      <p className="mt-5 text-white">
        We live to stretch the boundaries of what one can accomplish through
        difficult work and determination consistently. We have understood that
        the benefits presented by the web are for all intents and purposes
        boundless. These occasions have permitted me to acquire indispensable
        involvement with the development as a maker, business visionary, and
        individual alongside abilities that we can convey with us for the
        remainder of our life.
        <br />
        <br />
        Quick forward today, it has been an amazing excursion that would
        preferably require a novel to report, yet we are certain and confident
        that we are as yet on the principal page. We are sitting in the
        workplace that we had made and subsidized ourselves with all of the most
        recent innovation items. These endeavours would have appeared to be a
        dream a couple of years prior. The truth that the vast majority have
        invested energy of more than 4 years seeing our manifestations is as yet
        incredible.
      </p>
      <div className="row">
        {data.map((item) => {
          return (
            <div key={item.id} className="col-12">
              <Hoverableservicecard data={item} />
            </div>
          );
        })}
      </div>
    </div>
  );
}
