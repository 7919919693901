import React from "react";
import { Link } from "react-router-dom";

export default function Blogcard({data}) {
  console.log(data)
  return (
    <Link to={`/single-blog/${data.id}`}>
    <div className="blog-card-container">
      <img src={data.thumb} className="img-fluid" alt="shoot a story services" />
      <div className="content">
        <h4>{data.title}</h4>
        <h5>By<br/>{data.author}</h5>
      </div>

    
    </div>
    </Link>
  );
}
