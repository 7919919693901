import React from 'react'
import { Link } from 'react-router-dom'
import blogdata from '../../data/blogsdata'
import Title from '../shared/Title'

export default function Blogs() {
    return (
        <div className="blogs-main-container container">
            <Title title="blogs"/>
           
            <section className="more-blogs-container">
               
            <div className="row">
               { blogdata.map((item,idx)=>{
                    return(
                      
                        <section className="blogs-card col-11 col-md-6 col-lg-4 mx-auto my-5" key={item.id}>
                              <Link to={`/single-blog/${item.id}`}>
                        <img src={item.thumb} className="img-fluid"  alt=""  />
                        </Link>
                        <h4 className="mt-3">{item.title}</h4>
                        <p >{item.date}</p>
                      
                    </section>
                
                    )
                })}
            </div>
         
            </section>
        </div>
    )
}
