import React from "react";
import { Link } from "react-router-dom";
import blogdata from "../../data/blogsdata";
import Blogcard from "../shared/Blogcard";
import Title from "../shared/Title";
export default function Blogs() {
 
  return (
    <div className="container mt-5 ">
      <Title title="latest Blogs" />
      <div className="row">
        {blogdata.slice(0,4).map((item, idx) => {
          return (
            <div className="col-10 col-md-5 col-lg-3 mx-auto" key={idx}>
              <Blogcard data={item } />
            </div>
          );
        })}
      </div>
      <Link to={"/blogs"} className="explore-more-btn">View all</Link>
    </div>
  );
}
