import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import "./stylesheets/styles.css"
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from './components/hoc/Errorboundary';

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
    
            <App />
   
    </ErrorBoundary>
  </BrowserRouter>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
