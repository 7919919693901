import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function Movetoup({children}) {
    let {pathname}=useLocation()
    useEffect(() => {
        console.log(pathname);
    window.scrollTo(0,0)
    }, [pathname])
    return (
       <div>
           {children}
       </div>
    )
}
