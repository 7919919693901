import React from 'react'

export default function Title({title}) {
    return (
        <div className="title-container">
            <h2>{title}</h2>
            <section className="lower-border"></section>
        </div>
    )
}
