import React from "react";
import { Link } from "react-router-dom";
import Title from "../shared/Title";

export default function Aboutus() {
  return (
    <div className="about-home-main-container  container">
      <section className="about-content">
        <Title title="About us" />
        <div className="intro-text">
          <p className="mt-4">
            We go through different moods in a day, at times we are Happy, at
            times we are Sad, at time we are Confused, at times we are
            Surprised, at times we are Shocked, at times we are Depressed, etc.
            As every mood of us has a reason and story behind it. So are our
            pictures too will have. At Shoot a Story, we believe in bringing out
            the Story behind your mood in that particular picture by our
            Creative Photography. We firmly believe that every picture clicked
            has some story behind it. Let’s make each picture talk about their
            Story
          </p>
        </div>
        <Link to={"/about"} className="explore-more-btn">Know more</Link>
      </section>
      <div className="container">
        <img
          src="/assets/images/about-us-image.png"
          className="w-100 img-fluid mt-5"
          alt="shoot-a-story"
        />
      </div>
    </div>
  );
}
