import React from "react";
import { Link } from "react-router-dom";
import Hovercard from "../shared/Hovercard";
import Title from "../shared/Title";

export default function Services() {
  const images = [
    { image: "/assets/images/categoryimages/p6.png", title: "Fashion" },
    { image: "/assets/images/categoryimages/p4.png", title: "E-Commerce" },
    { image: "/assets/images/categoryimages/p3.png", title: "Portfolio" },
  ];
  return (
    <div className="services-home-main-container container">
      <Title title="our services" />
      <div className="intro-text">
        <h4>Capturing the moments that captivate your Heart</h4>
        <p className="mt-4">
          At "Shoot a Story" we have undying energy for work, travel, visual
          media, and associating with similar aggressive people. We additionally
          try to be one who can carry on with life in opportunity by completely
          putting forth a concentrated effort to something we really partake
          inconsistently. We offer a wide range of services covering almost
          every domain, most popular and known are Fashion Photography, Product
          Photography, Lifestyle, Wedding, Model Portfolio, and the list keeps
          going on and on.
        </p>
      </div>
      <div className="row mt-5">
        {images.map((item, idx) => {
          return (
            <div
              className="col-11 col-md-4 "
              key={idx}
              style={{ margin: "auto", padding: "0px" }}
            >
              <Hovercard image={item.image} title={item.title} />
            </div>
          );
        })}
      </div>
      <Link to={"/services"} className="explore-more-btn">Know more</Link>
    </div>
  );
}
