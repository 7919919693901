
const blogdata = [
  {
    id: 1,
    thumb:"/assets/Blog_images/page1/1.webp",
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 1`,
    date:"20 dec 2021",
    content: `

        <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 1
        </h4>
        <p>
          Here we will be creating a series of Blogs curated specially for You
          on What Is Photography and It all Different Categories:
        </p>
      </div>

        <div class="blog-content">
        <p>
          <strong>The Product Photography: -</strong> Product photography is any picture of a useful available to be purchased. Otherwise called business photography, these pictures are intended to captivate customers to buy the shot items. They highlight item subtleties and elements, supplemental to composed duplicate and item depictions. The thought is to provide expected purchasers with a full impression of the item.
        </p>
      </div>
      <div class="blog-content">
      <p>
        <strong>What is object photography? -</strong> Object photography is the point at which you snap a picture of a lifeless thing. Thusly, item photography is actually a type of article photography — except if the item is living, similar to a delicious. In object photography, things are normally immobile, or still. 
      </p>
    </div>
    <h4 style=color:white>Kinds of item photography: -  </h4>
    <div class="left-right-image-container container">

    <div class="img-container" style=order:0>
      <img src="/assets/Blog_images/page2/1.webp" alt="" />
    </div>
    <div class="content-container">
      <p>
      <p>
      <strong>White Background :-</strong> White foundation photographs are the ones you see on web-based business commercial centres like Amazon, AliExpress, and eBay. Likewise noted as individual item photographs (however at times white foundation shots highlight numerous pictures). This is ostensibly the most well-known item photograph type you'll see. Indeed, when we investigated the item photographs from the top style brands, we saw as that 95% of them had white foundation shots.    
      </p>
      </p>
    </div>
  </div>
    

  <div class="blog-content">
  <p>
    <strong>The Contextual Photography: -</strong>  Contextual shots include items being used. These kinds of photographs are ideal since they tell customers the best way to utilize the items in their own lives, and it additionally provides them with a feeling of scale. Individuals can envision themselves utilizing the item when you have these kinds of photographs.  </p>
</div>

<div class="blog-content">
<p>
  <strong>The Scale Shots: -</strong>  Scale shots are item photographs that give a casing of reference so individuals can imagine how huge the items are. While item specs and aspects are engaging, now and again customers need a picture to perceive how huge or little it is in contrast with normal articles.  
  The beneath picture shows a teacup and going with dish, alongside somebody holding it to provide you with a feeling of exactly how minuscule this thing is.    </p>
</div>

<div class="blog-content">
<p>
  <strong> The Abstract Photography: - </strong> Detailed item shots show little components that aren't really noticeable in a standard item photograph. You'll regularly see this with attire, footwear, and extras — particularly as you get into the extravagance classifications. Piggery, which makes extravagance pet frill, shows the subtleties of their painstakingly fabricated canine saddles and chokers.  
  </p>
</div>

<div class="blog-content">
<p>
Abstract photography, otherwise called calculated, concrete, o++++r exploratory photography, is a type that is hard to characterize. Unique photos regularly use tone, light, shadow, surface, shape, or structure to pervade an inclination, sensation, or impression — without really giving an authentic picture of the article or scene that is being shot.  

</p>
</div>

<div class="blog-content">
<p>
Shots that ordinarily fit into this sort of photography are not quickly connected with a recognizable article in the watcher's eye since they are made by separating a specific item or regular scene such that eliminates context-oriented subtleties from the picture, either through outlining the shot innovatively or through picture altering thereafter.
</p>
</div>

<img src="/assets/Blog_images/page2/2.webp" class="img-fluid"/>


<div class="left-right-image-container container">

    <div class="img-container" style=order:1>
      <img src="/assets/Blog_images/page2/3.webp" alt="" />
    </div>
    <div class="content-container">
      <p>
        <strong>The Adventure Photography: -</strong> Adventure photography is considerably more plain as day than unique photography. This kind of photography catches pictures of undertakings, ordinarily in nature. It regularly includes surprising scene shots, audacious explorers, and provoking shooting conditions due to openness to specific areas and moving climate conditions while taking photos outside.  
        Hauling camera gear into remote, rough areas gives an extra test to experience picture takers. Climbing, trekking, exploring, and setting up camp with gear is something that picture takers of this specialty classification are intimately acquainted and alright with.
      </p>
    </div>
  </div>
    

<div class="blog-content">
<p>
<strong>The Architectural Photography: -</strong> Another simple kind, structural photography is the point at which the really topic of the photo is a structure's outside or inside. These shots will more often than not be really precise portrayals of the development that are taken in a manner that is additionally tastefully satisfying to the eye.  
</p>
</div>

<div class="blog-content">
<p>
Outside shots generally use sunlight to catch the structure alongside adjacent arranging highlights. They can likewise be taken shots around evening time utilizing encompassing lighting from streetlamps, encompassing scene, or twilight.  
</p>
</div>

<div class="blog-content">
<p>
Inside shots can be corresponding to outside shots to additionally portray a specific engineering style or can be utilized as an independent.  
Point of view control is a critical part of this specialty because of the sheer size of most structures. The huge configuration view cameras of days of yore, slant/shift focal points, and post-handling would all be able to add to having an incredible chance of a major structure.  
</p>
</div>

<div class="blog-content">
<p>
The world's most seasoned enduring photo is a compositional photo taken in 1826 or 1827, making the class one of the primary kinds of set up photography. As engineering patterns have moved and structures of various styles have been raised all throughout the planet, photographic artists can dig profound into this specialty, zeroing in their endeavours on a particular design style like Contemporary, Tudor, Postmodern, Gothic, Victorian, Classical, and then some. </p>
</div>
<img src="/assets/Blog_images/page2/4.webp" class="img-fluid w-100"/>

    `
  }
  ,

  {
    id: 2,
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 2`,
    date:"20 dec 2021",
    thumb:"/assets/Blog_images/page1/2.webp",

    content: `

        <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 2
        </h4>
      </div>
      <img src="/assets/Blog_images/page3/1.webp" class="img-fluid mt-4"/>


        <div class="blog-content">
        <p>
          <strong>The Astro Photography: - </strong> Taking photographs of cosmic items like stars, planets, and meteors, heavenly occasions like lunar or sunlight-based obscurations and different peculiarities of the night sky the entire fall under the spacey umbrella of astrophotography. Astrophotography reformed the field of expert cosmic examination as long-lasting openings made it conceivable to record pictures of stars and amazing nebulae that are generally undetectable to the natural eye. In the long run, optical telescopes were intended to record light utilizing visual plates, basically working like monster cameras.  
          </p>
      </div>

      <div class="blog-content">
      <p>
      Picture takers can utilize both film and computerized cameras with long-term openness capacities to catch these kinds of photographs since light photons can aggregate over the long haul. Unique hardware and strategies, in any case, are fundamental for catching subtleties so getting into astrophotography is a big deal.         
       </p>
    </div>
    <div class="blog-content">
    <p>
    Because of its exceptional requirement for specific conditions and uncommon equipment, astrophotography is ordinarily a subsection of novice cosmology. In any case, photographic artists that have an enthusiasm for the night sky are additionally ready to partake by getting the right hardware, utilizing the right camera settings, and learning a couple of extraordinary strategies. Stands, telescopes, fixed wide-point visual focal point cameras, following mounts, imaging sensors, and post-handling devices are only a couple of the things required for incredible astrophotography.
    </p>
  </div>

  <div class="left-right-image-container container">

    <div class="img-container" style=order:1>
      <img src="/assets/Blog_images/page3/2.webp" alt="" />
    </div>
    <div class="content-container">
      <p>
        <strong>The Black & White Photography: - </strong> 
        Black and white (B&W) photography centres around catching a picture with no shading. This should either be possible through a camera setting or through altering a shading picture a while later. Monochrome pictures that utilization insignificant measures of lighting additionally fall under this class.  
        </p>

        <p>
        At the point when visual innovation initially started, practically all pictures caught were either high contrast or changing shades of sepia. Some early shading photography was hand-coloured however it was very uncommon and costly to make. As innovation progressed into the mid-twentieth century, shading photography became predominant, assuming control over the once-prevailing B&W class.  
        </p>
        <p>
       
        Regularly catching work of art, immortal, and exquisite shots, high contrast photography is basically viewed as compelling artwork photography nowadays. Craftsmanship films and other movies additionally in some cases utilize B&W as the picked tasteful, delivering a nostalgic or vintage vibe.          </p>
    </div>
  </div>

  <div class="blog-content">
      <p>
        <strong>The Business Photography: - </strong> Business photographs centre around the functioning scene, with symbolism being caught to help the development and improvement of a business, for the most part for advertising and limited time purposes. By catching pictures of the proprietors, their items, their groups, or their administrations, business photography permits organizations to recount to an anecdote about their contributions through photographs.  
        </p>
    </div>
    
    <div class="blog-content">
      <p>
      Picture photography, item photography, way of life photography, open photography (of things like studios or gatherings), and now and then even compositional photography fit into this class, contingent upon the business the business works in.  

      </p>
    </div>

    <div class="blog-content">
    <p>
    These photographs can be utilized as showcasing security for an organization's site, leaflets, and other special endeavours just as in articles addressing tech. Innovation, all things considered, is large business. What did we by any chance do before PCs?  
    </p>
  </div>

  <div class="left-right-image-container container">

  <div class="img-container" style=order:0>
    <img src="/assets/Blog_images/page3/3.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>The Candid Photography: -</strong> 
      Most photos of individuals are frequently presented, with the picture taker coordinating the shot and models. Real photography is a sort of photography that eliminates the presented part of the situation, with picture takers making efforts of individuals moving, precipitously, or off guard. Photographic artists should intend to catch subjects in a characteristic state, without expecting to direct or present them to get the right picture. This doesn't really imply that subjects don't know that photographs are being taken – assent is as yet a significant piece of having the option to utilize those photographs! It should mean, notwithstanding, that your genuine pictures will catch bona fide scenes, responses, and looks of individuals in them.  

      </p>

      <p>
      Secret photography is viewed as a subset of open photography and happens when subjects are absolutely unconscious of their photo being taken. Because of its subtle nature, open photography will in general utilize little hardware that is frequently circumspect all together not to upset or divert subjects when shooting. Streak photography is additionally not normal when accepting sincere shots as the glimmer draws consideration and can make subjects modify their conduct towards a less regular, more controlled position.      
       </p>
      </div>

 
</div>
<div class="left-right-image-container container">

<div class="img-container" style=order:1>
  <img src="/assets/Blog_images/page3/4.webp" alt="" />
</div>
<div class="content-container">
<p>
 <strong>The Cityscape Photography: - </strong> 
 The metropolitan partner to scene photography, cityscape photography catches pictures of city horizons or segments of a city. These pictures can be taken during the day or night and generally include enormous segments of a city. Wide-point focal points are expected to accomplish these shots, yet zooming focal points can prove to be useful too to catch a more modest space of the city. Stands can be exceptionally valuable to forestall camera shake obscure. 
 </p>

 <p>
 Cityscapes are a flexible type of photography that can be caught whenever and from numerous areas. Higher rises, similar to housetops or highest levels of elevated structures, and less thick regions like waterfront promenades, can give heaps of choices to an extraordinary shot. Messing with the hour of day additionally gives a lot of freedoms to catch a cityscape at the mysterious brilliant or blue hour, and wherever in the middle.
 </p>
</div>
</div>

<div class="blog-content">
<p>
  <strong>The Commercial Photography: -</strong> Commercial photography is what is taken explicitly for business use, typically to advance or sell an item or administration. Picture takers are employed to make efforts of the item to be utilized in advertising guarantee like leaflets, menus, handouts, and then some. Pictures of administrations can be organized utilizing models or staff. Food photography, design photography, and item photography once in a while twofold as business photography. Headshots are one more famous piece of the riddle for this sort.  
  </p>
</div>

<div class="blog-content">
<p>
Affirming the sort and number of shots, just as sorting out must-have shots versus good to-haves, will assist with guaranteeing that an authorized photographic artist can convey what their customer needs  </p>
</div>
<img src="/assets/Blog_images/page3/5.webp" class="img-fluid mt-4"/>

    
    `
  },

  {
    id: 3,
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 3`,
    date:"20 dec 2021",
    thumb:"/assets/Blog_images/page1/3.webp",

    content: `
       
    <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 3
        </h4>
      </div>
     

  <div class="left-right-image-container container">

    <div class="img-container" style=order:1>
      <img src="/assets/Blog_images/page4/1.webp" alt="" />
    </div>
    <div class="content-container">
      <p>
        <strong>THE COMPOSITE PHOTOGRAPHY: -  </strong> 
        Some kinds of photography are made by joining different pictures to make one composite picture. Composite photography requires post-handling and altering of at least two shots to deliver a layered shot that actually looks durable.  
        </p>

        <p>
        During the 1880s, way before computerized photography and altering existed, composite picture was being delivered utilizing a procedure that permitted numerous openings to be taken on a similar visual plate. Nowadays however, separate pictures are mixed by utilizing layers, veils, and foggy spots. Montages of photographs, where a scene is shot in little pieces and afterward joined, and variety, in which an individual is caught on different occasions in a similar scene, can likewise make a composite photo. Centre stacking, where a scene is caught from various points or marks of concentration and afterward mixed into one, is one more procedure used to make a huge profundity of field.  
        </p>
          </div>
  </div>

  <div class="blog-content">
      <p>
      These kinds of photos can make interesting, imaginative, and article pictures that are inconceivably innovative and regularly exceptionally striking. Because of their relative intricacy, they additionally take more time to deliver. 
       </p>
    </div>
    
  

 

  <div class="left-right-image-container container">

  <div class="img-container" style=order:0>
    <img src="/assets/Blog_images/page4/2.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE CREATIVE PHOTOGRAPHY: - </strong> 
      Another difficult to-characterize classification, imaginative photography incorporates an immense assortment of ideas and methods. It empowers experimentation and innovativeness in organization, just as in the hardware and methods used to catch a picture (composite photography can cover with this sort).  

      </p>

      <p>
      Different models incorporate fisheye focal point photography or gem ball photography which control the viewpoint of the shot. High unique reach (HDR) photography and constrained viewpoint photography likewise fall under this visual specialty. Ideas like the as of late famous "cake crush" photoshoots for infants and little children are one more type of imaginative photography.  
      </p>
      <p>
      Exploring different avenues regarding channels, surfaces, and murkiness, just as shade speed, numerous openings, and movement obscure can assist with making another point of view. Utilizing different types of media, like artwork on prints, shading manually, or reordering other printed pictures to make a montage, can likewise deliver an innovative last shot.       </p>
      </div>
</div>
 

      <div class="blog-content">
      <p>
        <strong>THE DOCUMENTARY PHOTOGRAPHY: -</strong> Considered by some to be a piece of expert photojournalism, narrative photography catches pictures of reality, typically with regards to verifiable occasions, just as the ordinary. A distinctive contrast between the two is that narrative photography will in general account an occasion, subject, climate, or point for a drawn-out timeframe, adding setting to a specific storyline. Photojournalism for the most part manages a more limited term, more "breaking news" style of photography.  
        </p>
      </div>

      <div class="blog-content">
      <p>
      At times shot in B&W for that ageless look, narrative photography can likewise be accomplished by novice or creative picture takers. One more famous subset of this kind of photography can be found in the realm of scholastics. Protection photography and road photography may likewise be viewed as a piece of this classification, however can likewise remain solitary as isolated classes.  
              </p>
      </div>


<img src="/assets/images/about-us-image.png" class="img-fluid mt-4"/>

<div class="left-right-image-container container">

  <div class="img-container" style=order:1>
    <img src="/assets/Blog_images/page4/3.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE EDITORIAL PHOTOGRAPHY: -  </strong> 
      Usually utilized in papers and magazines, publication photography fuses parts of style, sports, and occasion photography, regularly shot in a sincere nature. By and large, these sorts of photographs are intended to delineate a story that is newsworthy, instructive, as well as enlightening.  

      </p>

      <p>
      Since these pictures are intended to be displayed in printed media, they will more often than not go with text, giving extra visual setting to a story line or undertaking. Not quite the same as business and promoting pictures, publication shots are utilized to go with articles and along these lines fall under a specific authorizing model.   
      </p>  
      <p>
      In any case, some preparation of a twofold openness shot is needed to decide an arrangement that will function admirably. A base photograph should be caught, then, at that point, the layer photo(s), and afterward the two pictures should be altered and converged into one. The foundation of the base photograph should be taken out prior to adding in the layer(s) and mixing them together for the end-product.       </div>
</div>
 


<div class="left-right-image-container container">

  <div class="img-container" style=order:0>
    <img src="/assets/Blog_images/page4/4.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE EDITORIAL PHOTOGRAPHY: - </strong> 
      Usually utilized in papers and magazines, publication photography fuses parts of style, sports, and occasion photography, regularly shot in a sincere nature. By and large, these sorts of photographs are intended to delineate a story that is newsworthy, instructive, as well as enlightening.  

      </p>

      <p>
      Since these pictures are intended to be displayed in printed media, they will more often than not go with text, giving extra visual setting to a story line or undertaking. Not quite the same as business and promoting pictures, publication shots are utilized to go with articles and along these lines fall under a specific authorizing model.  
      <p>
      Article shots typically can't be utilized for business purposes to sell an item, except if the picture taker gets fitting model or property discharges. Purchasers who buy these kinds of photos from stock organizations are answerable for following the permitting conditions.</div>

  </div>
  </div>

   <div class="blog-content">
      <p>
        <strong>THE EVENT PHOTOGRAPHY: - </strong>  Event photography catches visitors, exercises, and the mood of a social affair. Weddings, christenings, corporate gatherings, birthday events, moves, grants services, burial services, meetings, live shows, and different commitment fall under this class of photography.  

        </p>
   </div>

  </div>
      <div class="blog-content">
      <p>
      These shots can be utilized for individual, wistful worth, just as to advance or market an occasion, association, or organization for business purposes. Falling under the genuine photography class too, a few shots at the occasion might be presented to archive the visitors in participation, however a large portion of them are normally taken as the situation normally develops.                </p>
      </div>

      <div class="blog-content">
      <p>
        <strong>THE FAMILY PHOTOGRAPHY: -  </strong>  Taking photographs of a family in presented or genuine picture is known as family photography. These kinds of shots are regularly authorized by the family for individual use to hang in the family home. They catch the connections between guardians, kids, kin, and here and there more distant family. Family get-togethers, weddings, and other family events are an optimal chance to rehearse this kind of photography. New-conceived photography and shooting occasions like cake crushes can likewise fall under this kind. 

        </p>
      </div>

      <div class="blog-content">
      <p>
      When utilized monetarily, catching family photographs should feel bona fide and genuine. Like real photography, these pictures can show the association and delicate minutes got between relatives or they can imitate an arranged family photoshoot. Fundamental, in any case, is that the models employed either are real family or can convey the closeness related with family. Any other way, you can wind up with an abnormal gathering of models professing to family. 
      </p>     
     </div>
    
    `
  },
  {
    id: 4,
    thumb:"/assets/Blog_images/page1/4.webp",
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 4`,
    date:"20 dec 2021",
    content: `
       
    <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 4
        </h4>
      </div>
     
      <div class="blog-content">
      <p>
      <strong>THE FILM PHOTOGRAPHY: - </strong>Film photography traces all the way back to the last part of the 1800s and is the forerunner to computerized photography. "Security film" was presented by Kodak in 1908, albeit past cycles of film were utilized as right on time as 1889, yet were produced using the considerably more combustible material, nitrocellulose — generally alluded to as "nitrate film". Shading film was presented for home motion pictures in 1935 by Kodachrome, and 35mm film lengths were utilized for still cameras starting at 1936.  

      </p>
    </div>
 

  <div class="blog-content">
      <p>
      35mm shading film utilizes numerous layers and channels to catch a picture that, once uncovered or created, produces a shading photograph. High contrast photograph handling was less perplexing and not so temperature-delicate as shading photograph handling. In any case, the handling of high contrast photographs was less accessible on a business scale, inciting the plan of high contrast film (despite the fact that the handling was done similarly as standard shading film). Film size and speed are the two variables to consider when shooting on film. Film speed depicts the film's limit affectability to light, indicated by the ISO scale.       
       </p>
    </div>

    <div class="blog-content">
    <p>
    Until the mid-21st century, film photography was the chief type of photography. As computerized innovations turned out to be all the more broadly accessible, numerous buyers abandoned film and moved to advanced configurations. In 1981, Sony delivered the primary buyer electronic camera which was trailed by Fuji's first computerized camera, delivered in 1989. Be that as it may, a new pattern in photography throughout the last decade has seen a recovery in film photography, with organizations like Kodak and Fujifilm currently returning to their product offering up to fuse film contributions by and by. 
     </p>
    </div>

    <div class="blog-content">
    <p>
    Most present-day picture takers shoot utilizing advanced cameras nowadays, yet fans and aficionados of the appeal and stylish of film actually utilize this unique visual procedure to make astonishing photographs utilizing completely manual settings on simple cameras. This sort of photography isn't quite so straight-forward as shooting on an advanced camera; however, it offers a more profound learning opportunity for proficient picture takers as they get to see how their camera capacities and how changing specific settings modify the craft of catching a photograph.      </p>
   </p>
    </div>

    <div class="blog-content">
    <p>
    <strong>THE FINE ART PHOTOGRAPHY: -</strong> Fine craftsmanship photography is made by a craftsman who is utilizing photography as a way to bring a thought, idea, message, or feeling to life in symbolism. It is intended to satisfy the inventive vision of the craftsman, passing on a particular inclination to the watcher through the shot. These pictures are frequently outlined and shown as workmanship on dividers similarly a canvas would be.
    </p>
  </div>


<div class="blog-content">
    <p>
    Now and again artistic work photography covers with different classifications of photography, for example, photojournalism and style photography. Authentic photography, that which tries to unbiasedly catch the topic or scene as it truly is, appears differently in relation to compelling artwork photography, which is intended to catch the craftsman's abstract expectation behind the picture. 
     </p>
  </div>

  <div class="blog-content">
  <p>
  Becoming famous in the Victorian period and advancing to the current day, subsets of this kind of photography incorporate naked photography, pictures, and regular scenes. Ansel Adams is viewed as a model of compelling artwork scenes with his dazzling high contrast photography caught in public stops.  
   </p>
  </div>


  <div class="left-right-image-container container">

  <div class="img-container" style=order:1>
    <img src="/assets/Blog_images/page5/1.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE LIFESTYLE PHOTOGRAPHY: -  </strong> 
      This kind of photography catches individuals, in actuality, circumstances, recounting to tales about their lives such that archives the scene, while as yet being creative. Regularly, scenes are presented or coordinated by the picture taker, however the symbolism is intended to show up as normal as could really be expected. Some of the time way of life photography is shot openly.  

      </p>

      <p>
      The Lifestyle photography likewise covers with style photography, narrative photography, and family photography as it attempts to catch previews of the subject's lives. These shots can be utilized for individual use, outlined in the family home, or for business or article use to move or elevate a specific way to deal with day-to-day existence. 
      </p>
   </div>
</div>
<img src="/assets/Blog_images/page5/2.webp" class="img-fluid mt-4"/>

  <div class="blog-content">
  <p>
  <strong>THE LONG EXPOSURE PHOTOGRAPHY:- </strong>- Long openness photography, otherwise called time openness or slow-shade photography, utilizes a long span of screen speed to catch fixed parts of a scene forcefully, while obscuring or spreading the moving angles. It varies from traditional visual practices as it catches a picture throughout a drawn-out timeframe, rather than in a solitary preview.  

  </p>
  <p>
  Capturing moving water, like a cascade or stream, utilizing long openness makes a fog like impact around the water, obscuring together its development while the remainder of the view holds its sharpness.  

  </p>
  <p>
  Night photography frequently utilizes long openness to catch star trails or other heavenly items. Light from different sources like streetlights or headlights can likewise cause fascinating impacts with regards to a shot that is being taken utilizing long openness. Light artwork is another strategy that utilizations long openness and a portable light source, like a spotlight or sparkler, to make an exceptional "painting" with the strokes of light.  
  </p>
  </div>

<div class="left-right-image-container container">

  <div class="img-container" style=order:1>
    <img src="/assets/Blog_images/page5/3.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE MACRO PHOTOGRAPHY: -  </strong> 
      Also called photograph macrography or basically macrography, full scale photography catches outrageous close-ups of regularly tiny subjects. Creepy crawlies and plants or blossoms are well known topic in this classification. Other full scale symbolism can incorporate close-up shots of food, water beads, regular things like textures or plumes, toys, eyes, gems, and other dynamic examples and surfaces.  

      </p>

      <p>
      Large scale photography requires particular focal points to catch a genuine multiplication proportion of the topic, with profundity of field and lighting being significant contemplations in changing the shot. Augmentation cylinders can likewise be utilized to broaden focal points for that very close catch.  
      </p>
      </div>
</div>
<div class="blog-content">
<p>
Photomicrography, which utilizes a computerized magnifying lens to catch pictures, is accomplished when the generation proportion of the shot is more prominent than 10:1.  
 </p>
</div>

  <div class="left-right-image-container container">

  <div class="img-container" style=order:0>
    <img src="/assets/Blog_images/page5/4.webp" alt="" />
  </div>
  <div class="content-container">
    <p>
      <strong>THE MINIMALIST PHOTOGRAPHY: -  </strong> 
      Minimalist photography catches a scene utilizing restricted, or insignificant, components. Shading, lines, examples, shapes, and surfaces can have a major impact in these kinds of photographs. 

      </p>

      <p>
      Originating from the moderate craftsmanship development of the 1950s, moderate photography empowers creative straightforwardness. Shots regularly fuse normal scenes or scenes as the primary concentration, frequently caught at the crack of dawn, in the early morning, or under the obscurity of night when very few individuals are dynamic. Pictures radiate a feeling of desolateness or void, exhibiting the tidiness and straightforwardness of utilizing restricted components in the shot's arrangement.       </p>
       </div>
</div>
 

   
    
    `
  },
  {
    id: 5,
    thumb:"/assets/Blog_images/page1/5.webp",
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 5`,
    date:"20 dec 2021",
    content: `
       
    <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 5
        </h4>
      </div>

      <div class="left-right-image-container container">

      <div class="img-container" style=order:1>
        <img src="/assets/Blog_images/page6/1.webp" alt="" />
      </div>
      <div class="content-container">
        <p>
          <strong>THE NEWBORN PHOTOGRAPHY: - </strong> 
          Closely identified with family photography, new-conceived photography centres explicitly around catching pictures of new-conceived children. This sort of photography can incorporate the parent(s), yet can likewise catch the youngster alone in a comfortable or charming setting.  

    
          </p>
    
          <p>
          Some new-conceived photographic artists have a studio space set up to guarantee the security and prosperity of the child while photographs are being taken. Props and extras are staples.         
           </p>
           <p>
           New-borns might be caught when conscious, albeit many are captured while resting and more straightforward to present. Anne Geddes is a notable child picture taker, perceived worldwide for her notorious pictures of new-borns and children.  
           </p>
       </div>
    </div>

    <div class="left-right-image-container container">

      <div class="img-container" style=order:0>
      <img src="/assets/Blog_images/page6/2.webp" alt="" />
      </div>
      <div class="content-container">
        <p>
          <strong>THE NIGHT PHOTOGRAPHY: - </strong> 
          All photographs taken at sunset under the class of night photography. Camera settings should be changed and here and there unique strategies are utilized to catch pictures in these low-lit conditions.  

          </p>
    
          <p>
Long openings and time-slips are well known procedures, as is light painting and winding photography. Astrophotography and Milky Way photography can have a place with this sort of photography too.  
           </p>
          
       </div>
    </div>

    <div class="blog-content">
    <p>
    Other than catching the night sky, other normal topic in night photography incorporates city horizons, modern scenes and manufacturing plants, firecrackers, nightlife or live shows, streets, lit caverns, deserted structures or waterways lit by moonlight, lighting, magma, aurora borealis or aurora australis, event congregation rides, lit airplane, bioluminescence, and other imaginative wellsprings of light.  
    Mounts, screen discharge links or self-clocks, streak units, manual concentration, far off clocks, and uncommon camera focal points would all be able to be valuable in catching night photography. A large number currently likewise join a Night Mode to work with portable night photography.  
    </p>
  </div>

  <div class="blog-content">
   <p>
      <strong>THE PHOTO JOURNALISM: - </strong> A type of news coverage, photojournalism utilizes pictures to recount a report. In spite of the fact that it is firmly identified with different sorts of photography like narrative photography, road photography, still-life photography, or war photography, it is one of a kind in that it should fulfil severe moral guidelines. Work should be straightforward, fair-minded, and should recount a visual story meeting editorial term.  

   </p>
  </div>

  <div class="blog-content">
     <p>
     Photographs are frequently utilized in news media or magazine articles. Photojournalists are correspondents that convey photography hardware and settle on split-second choices in what pictures to catch to address a report as it unfurls. Frequently, this implies they are in outrageous conditions concerning their own wellbeing.  

     </p>
  </div>

<div class="blog-content">
<p>
The class has starting points in war photography, starting as right on time as the mid-1800s. Its fame started to decrease during the 1970s as some photograph magazine distributions quit printing. All the more as of late, these sorts of photographs are entering craftsmanship exhibitions, close by artistic work photography.
</p>
</div>

<div class="blog-content">
<p>
Social narrative photography, viewed as a type of photojournalism and subset of narrative photography also, records occasions on the planet through a social or natural concentration. Likewise, once in a while called concerned photography, this kind of photography features social issues, including catching the existences of devastated or weak networks.  
</p>
</div>

<div class="left-right-image-container container">

      <div class="img-container" style=order:0>
      <img src="/assets/Blog_images/page6/3.webp" alt="" />
      </div>
      <div class="content-container">
        <p>
          <strong>THE REAL ESTATE PHOTOGRAPHY: -  </strong> 
          Real home pictures are frequently utilized for business purposes to sell a home or work area or to feature a flawlessly executed plan in publications. Inside photography and outside photography both fit as sub-types of this classification.  

          </p>
    
          <p>
          Experience with lighting strategies, ideal camera settings, and stuff is significant for land photographic artists as indoor lighting is frequently not sufficient enough to have powerful chances. At the point when shots are utilized to sell a property, causing the space to feel warm and inviting is basic. Arranging scenes by cleaning up spaces and guaranteeing rooms are introduced as spotless and utilitarian, yet lived in, will likewise be critical to accomplishing a quality last shot.  
          </p>

          <p>
          For business spaces like workplaces or customer facing facades, similar standards can be applied. In any case, the usefulness of the space for various purposes should go over in the symbolism.  
          </p>
          
       </div>
    </div>

    <div class="blog-content">
      <p>
         <strong>THE SOCIAL MEDIA PHOTOGRAPHY: - </strong>Social media photography is a widely inclusive class of photography that utilizations pictures for web-based media posts on Instagram, Facebook, Pinterest, LinkedIn, or some other informal community. Pictures will more often than not have still-life or publication characteristics to them, yet style photography, item photography, food photography, and different kinds of photography are additionally regularly joined in web-based media posts.  

      </p>
    </div>

    <div class="blog-content">
      <p>
         When utilized for individual posts, pictures will generally catch a second or recount to a tale about an individual's life and ordinarily add to their web-based persona. In business crusades, pictures are chiefly used to advance a brand or sell an item or administration.
      </p>
    </div>

    <div class="blog-content">
    <p>
       <strong>THE SPORTS PHOTOGRAPHY: -  </strong> The demonstration of catching games symbolism is known as sports photography. This sort of photography is regularly viewed as a part of photojournalism, especially as to elite athletics. Sports picture takers regularly work for papers or sports magazines, yet may likewise make symbolism for promoting purposes. Beginner sports photography as a rule has a place with the vernacular photography specialty, where the emphasis is on catching the daily existence of customary individuals.
    </p>
  </div>

  <div class="blog-content">
    <p>
    Since most games are high speed, camera stuff and settings should have the option to catch the activity as it occurs, with shade speed being generally basic. Area is additionally a significant piece of having an incredible chance. The kind of game being shot will decide a great deal of the necessities for the camera body and focal point that works best. Individual games, like golf, boxing, cycling, or Olympic style sports might zero in on a little profundity of field, catching the power of the members. Group activities, similar to football, hockey, soccer, and such, require a bigger profundity of field to appropriately catch the entirety of the activity and the different groups taking an interest. 
    </p>
  </div>
  <img src="/assets/Blog_images/page6/4.webp" class="img-fluid" alt="" />


   
    
    `
  },

  {
    id: 6,
    thumb:"/assets/Blog_images/page1/6.webp",
    author:"Amit sharma",
    title:`WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
    CATEGORIES IN DETAIL – Part 6`,
    date:"20 dec 2021",
    content: `
       
    <div class="blog-title-container mt-5">
        <h4>
          <span>Photography - </span>WHAT IS PHOTOGRAPHY AND ITS DIFFERENT
          CATEGORIES IN DETAIL – Part 6
        </h4>
      </div>

      <div class="left-right-image-container container">

      <div class="img-container" style=order:1>
      <img src="/assets/Blog_images/page7/1.webp" alt="" />
      </div>
      <div class="content-container">
        <p>
          <strong>THE STILL-LIFE PHOTOGRAPHY: - </strong> 
          Still-life photography is another all-encompassing sort of photography where lifeless things are organized in a tastefully satisfying way and went for one or the other individual, article, or business use. Pictures generally fuse some masterfulness, with the photographic artist having inventive elbowroom in how to organize the organization of the shot. Lighting and outlining additionally have a significant influence in the arrangement.  

          </p>
    
          <p>
          Famous still-life topic incorporates food, blossoms, and plants, individual work area space or work areas, family things like dishes, containers, and plates organized in inventive or imaginative ways, and such.
                     </p>
           <p>
           Since still-life photography will in general be based on the plan of the items being shot and the lighting utilized, photographic artists, all things considered, and abilities can take wonderful photographs, without essentially expecting to utilize confounded procedures or stuff.  
             </p>
       </div>
    </div>

    <div class="left-right-image-container container">

      <div class="img-container" style=order:0>
      <img src="/assets/Blog_images/page7/2.webp" alt="" />
      </div>
      <div class="content-container">
        <p>
          <strong>THE SURREAL PHOTOGRAPHY: -</strong> 
          Surreal photography, like theoretical photography in that the last picture isn't in every case quickly conspicuous, urges photographic artists to track down one-of-a-kind viewpoints to catch. Part of a more extensive surrealist social development that started during the 1920s, surrealist photographic artists utilized darkroom stunts and optical deceptions to create illusory shots before advanced altering instruments turned into the standard.  

          </p>
    
          <p>
          Submerged picture and submerged scene photography can generally be delegated dreamlike because of their extraordinary climate. Scene photography caught in the right conditions are additionally ready to fuse parts of oddity.            </p>
          
       </div>
    </div>

    <div class="blog-content">
      <p>
        Food photography, style photography, and theoretical representations or closeups are now and again shot considering a strange stylish. Pictures are caught and frequently altered a short time later to add a creepy or extraordinary impact. Building photography, just as still life photography, give more freedoms to strange topic.  
        The ultimate objective of this class of photography is to convey pictures that are roused by enthusiasm and an interesting point of view, obscuring the lines between a fanciful setting and reality.  
      </p>
    </div>
    <img src="/assets/Blog_images/page7/3.webp" class="img-fluid mt-4 w-100" style="max-height:400px"/>

    <div class="blog-content">
        <p>
           <strong>THE STREET PHOTOGRAPHY: - </strong> The craft of catching a scene in a public spot, especially in the city, is called road photography. A significant number of these sorts of photos are likewise viewed as real in nature, typically un organized and shot immediately.  
       </p>
    </div>

    <div class="blog-content">
      <p>
         Contingent upon the country, there are sure agree laws to consider when taking photographs of individuals in broad daylight. Monitoring what these laws are in a given area is a significant piece of catching road photography for more extensive use.  
    </p>
    </div>

    <div class="blog-content">
      <p>
          Another kind that is firmly connected is metropolitan photography in which picture takers catch road scenes in city settings. Representation and design frequently have an impact in these pictures too.  
      </p>
    </div>

    <div class="blog-content">
       <p>
          Topic doesn't generally have to incorporate individuals, be that as it may. Catching conditions that do exclude apparent human movement can likewise be viewed as road photography. In those cases, human presence is normally suggested through the structure of the picture. While additionally having numerous similitudes to narrative photography, road photography will in general be less conscious in its intentional or characterized informing.  
       </p>
    </div>

    <div class="blog-content">
        <p>
          <strong>THE TIME-LAPSE PHOTOGRAPHY: -</strong>  In time-slip by photography, a progression of casings of a similar scene or topic are caught to portray a condition of progress or variance. The eventual outcome is an assortment of shots that can be played back like a brief video that shows the item or subject being impacted by the progression of time.    
      </p>
    </div>

    <div class="blog-content">
      <p>
        Famous topic incorporates things like the movement of the sun, stars, moon, and other divine bodies in the sky, the development of a plant, the rot of a piece of food, the advancement of a major venture like another structure, or individuals and traffic moving around in a city.  
      </p>
    </div>

    <div class="blog-content">
      <p>
      Time-slips utilize an equation to accomplish their particular moving quality. Seen speed of the topic rises to the projection's casing rate, partitioned by the camera's edge rate, duplicated by the genuine speed of the scene occurring (math!). Recorded shots will change in how rapidly they seem to move, in light of this computation. Time-slip by photography additionally utilizes short and long openness times, as well as altering the speed of the camera, to control the measure of movement obscure present in the edges. Consolidating this procedure with others like high-dynamic-range (HDR) imaging and day-to-night advances can create dazzling symbolism.        
      </p>
    </div>

    <div class="left-right-image-container container">

      <div class="img-container" style=order:0>
        <img src="/assets/Blog_images/page7/4.webp" alt="" />
      </div>
      <div class="content-container">
          <p>
            <strong>THE TRAVEL PHOTOGRAPHY: - </strong> 
            Documenting a spot, especially one that features the social, verifiable, touristic, or notorious parts of the given region, is called travel photography. Tracing all the way back to the 1850s, the class had been made unquestionably well known by movement distributions like National Geographic and Conde Nast Traveller.  

          </p>
    
          <p>
          Pictures can be utilized for article or business purposes, portraying the mood of a spot, just as the inclination one gets from the land, individuals, and societies that occupy it. Extremely expansive in its topic, the class utilizes components of representation, scene photography, design photography, road photography, and night photography, among others. B&W photography is likewise a usually utilized method.  
          </p>

       </div>
    </div>

    <div class="blog-content">
      <p>
         <strong>THE UNDERWATER PHOTOGRAPHY: - </strong> Taking photos while submerged, typically while scuba jumping, swimming, or from a submerged vehicle or computerized camera brought down from the surface, is known as submerged photography. This kind of photography brings about pictures of marine life, the underwater climate (counting wrecks, geographical elements, for example, cave frameworks and other submerged departures), and additionally individuals seeking after submerged exercises. All the more as of late, submerged symbolism has likewise been utilized to record the condition of waterways and their biological systems with regards to issues of environmental change and contamination.  
      </p>
    </div>

    <div class="blog-content">
      <p>
      Hardware is a significant part of this sort as cameras tend not to be waterproof naturally. In the event that they will be, they are generally restricted to a specific profundity. Go Pros or cell phones in plastic pockets are here and there utilized as a door into submerged photography prior to putting resources into further developed camera gear and appropriate, regularly costly, submerged lodging for a specific camera. 
      </p>
    </div>

    <div class="blog-content">
       <p>
       Shooting in water likewise accompanies acclimations to the focal point as water diminishes the point of view for a focal point by 25-30%. Water lucidity, the presence of waves or air pockets, flows, risky marine life, and nearness to the ocean bottom will all effect shots also. Season of day and area according to the sun likewise play a huge element in how submerged photographs get caught, as do the settings of the camera being utilized. This type is, thusly, more testing to dominate, basically because of the extra expenses for hardware and unique wellbeing worries of the shooting climate.        </p>
    </div>










  
    `
  }
]
export default blogdata