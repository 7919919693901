import React, { useState } from "react";
import { AiOutlineMenu ,AiOutlineClose} from "react-icons/ai";
import { Link } from "react-router-dom";
// import {GrClose} from "react-icons/gr"

const styleLink={
  color:"white",
  textDecoration:"none"
}
const Sidebar=function({show,handleShow}){
  return(
    <div className="sidebar-main-container" style={{display:show?"block":"none"}}>

      <AiOutlineClose size={30} onClick={handleShow} style={{position:"absolute",top:"40px",right:"100px",color:"white"}}/>
        <ul >
        <li><Link to="/" style={styleLink} >Home</Link></li>

          <li><Link to="/services" style={styleLink}>services</Link></li>
          <li><Link to="/about" style={styleLink}>about us</Link></li>
          <li><Link to="/pricing" style={styleLink}>pricing</Link></li>
          <li><Link to="/blogs" style={styleLink}>blogs</Link></li>
          <li><Link to="/contact" style={styleLink}>contact us</Link></li>
        </ul>
    </div>
  )
}
export default function Navbar() {
  const [showsidebar,setshowsidebar]=useState(false)

  const handleShow=()=>{
    setshowsidebar(!showsidebar)
  }
  return (
    <nav className="main-navbar-container container">
      <div className="logo-container">
        <img src="/assets/whitelogo.png" width="100" alt="shoot-a-story" />
      </div>
      <div className="navbar-link-container ">
        <ul>
        <li><Link to="/" style={styleLink} >Home</Link></li>

          <li><Link to="/services" style={styleLink}>services</Link></li>
          <li><Link to="/about" style={styleLink}>about us</Link></li>
          <li><Link to="/pricing" style={styleLink}>pricing</Link></li>
          <li><Link to="/blogs" style={styleLink}>blogs</Link></li>
          <li><Link to="/contact" style={styleLink}>contact us</Link></li>
        </ul>
      </div>
      <div className="menu-expand-icon">
        <AiOutlineMenu size={30} onClick={handleShow} />
      </div>
      <Sidebar show={showsidebar} handleShow={handleShow}/>
    </nav>
  );
}




