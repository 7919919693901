import React from "react";

export default function Introcard() {
  return (
    <div className="about-intro-container container ">
      <div className="intro-text mt-5 ">
        <h3>It’s All in The Click</h3>
      </div>

      <div className="about-content-container my-5">
        {/* <h5>The Art of Visual storytelling (1 line heading)</h5> */}
        <p>
          We go through different moods in a day, at times we are Happy, at
          times we are Sad, at the time we are Confused, at times we are
          Surprised, at times we are Shocked, at times we are Depressed, etc. As
          every mood of us has a reason and story behind it. So are our pictures
          too will have.
          <br />
          <br />
          At Shoot a Story, we believe in bringing out the Story behind your
          mood in that particular picture by our Creative Photography.
          <br />
          <br />
          We firmly believe that every picture clicked has some story behind it.
          <br />
          <br />
          Let’s make each picture talk about their Story at “SHOOT A STORY”
        </p>
        <img src="/assets/aboutbg.webp" className="img-fluid" alt="" />
        <h1 className="my-5"> why consider us</h1>
        <ul className="my-5">
          <h4>Passion</h4>
          <li>Passion to deliver the best in every work we do </li>
        </ul>
        <ul className="my-5">
          <h4>Flexibility</h4>
          <li>
            Flexibility to explore all the options to get that one perfect shot{" "}
          </li>
        </ul>
        <ul className="my-5">
          <h4>An Eye for Detail</h4>
          <li>
            It’s all about the detail to make every click speak its own story{" "}
          </li>
        </ul>

        <ul className="my-5">
          <h4>Good People Skills</h4>
          <li>We know our team and we love working with our clients </li>
        </ul>
        <ul className="my-5">
          <h4>Creativity and Imagination</h4>
          <li>
            We believe Creativity is the Soul {"&"} Imagination is the Heart of
            the Photography
          </li>
        </ul>
      </div>
    </div>
  );
}
