import Home from "./components/pages/Home";
import Navbar from "./components/shared/Navbar";
import {Routes,Route} from "react-router-dom"
import Services from "./components/pages/Services";
import About from "./components/pages/About";
import Blogs from "./components/pages/Blogs";
import Contact from "./components/pages/Contact";
import Pricing from "./components/pages/Pricing";
import Singleblog from "./components/pages/Singleblog";
import Category from "./components/pages/Category";
import Movetoup from "./hoc/Movetoup";

function App() {
  return (
    <div className="App">
        <Movetoup>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/services" element={<Services/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/blogs" element={<Blogs/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/pricing" element={<Pricing/>}/>
        <Route path="/single-blog/:id" element={<Singleblog/>}/>
        <Route path="/photography/:category" element={<Category/>}/>



      </Routes>
      </Movetoup>
      {/* <Home/> */}
    </div>
  );
}

export default App;
