import React from "react";
import Title from "../shared/Title";
import { useParams } from "react-router-dom";

export default function Category() {
  const params=useParams()
  console.log(params.category)
  return (
    <div className="container">
      <Title title={params.category} />

      <div className="row mt-5">
       {
           [1,2,3,4].map((item)=>{
               return(
                <div className="card-container col-11 mx-auto col-md-6 col-lg-4" key={item}>
                <figure>
                  <img
                    src="/assets/images/categoryimages/p1.png"
                    alt=""
                    className="img-fluid"
                  />
                  <figcaption className="text-center text-white">
                    <h3 className="mt-2"> Puglia, Italy.</h3>
                  </figcaption>
                </figure>
              </div>
               )
           })
       }
      </div>
    </div>
  );
}
