import React from 'react'
import Title from '../shared/Title'
import {FaFacebookF, FaTwitter} from "react-icons/fa"
import {AiFillInstagram} from "react-icons/ai"
export default function Contact() {
    return (
        <div className="contact-main-container">
            <Title title="contact us"/>

            <div className="main-contact-content-container">
             

                <div className="right-container">
                    <form action="" method="post">
                        <label htmlFor="text" className="mt-4">name</label><br/>
                        <input type="text" /><br/>
                        <label htmlFor="email" className="mt-4">email</label><br/>
                        <input type="email" /><br/>
                        <label htmlFor="subject" className="mt-4">subject</label><br/>
                        <input type="text" /><br/>
                        <label htmlFor="message " className="mt-4">Message</label><br/>
                        <textarea rows={6} />
                        <input type="button" value="submit" />
                    </form>
                    
                </div>
                <div className="left-container">
                 
                    <p className="heading">Phone</p>
                    <p>9098124564</p>
                    <p>9098124564</p>

                    <p className="heading">Email-address</p>
                    <p>gmail@gmail.com</p>
                    <p>gmail3@gmail.com</p>

                    <p className="heading">Address</p>
                    <p>this is shy house new delhi -110044</p>
                    <div className="social-media">
                        <span><AiFillInstagram/></span>
                       <span> <FaFacebookF/></span>
                       <span> <FaTwitter/></span>
                    </div>
                </div>
            </div>
        </div>
    )
}
