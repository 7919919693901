import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div className="hero-main-container ">
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block img-fluid"
            src="/assets/Banners/3.png"
            alt="First slide"
          />
          <div className="content first-slide">
            <h1>Everyone is Photogenic</h1>
          </div>
          <div className="buttons-container">
            <Link to={"/services"} className="service-btn button">
              Services Offered
            </Link>
            <Link to={"/contact"} className="contact-btn button">
              Contact Us
            </Link>
          </div>
        </Carousel.Item>

        {/* second slide */}

        <Carousel.Item>
          <img
            className="d-block img-fluid"
            src="/assets/Banners/2.png"
            alt="First slide"
          />
          <div className="content">
            <h1>Adding Moments of Life</h1>
          </div>
          <div className="buttons-container">
            <Link to={"/services"} className="service-btn button">
              Services Offered
            </Link>
            <Link to={"/contact"} className="contact-btn button">
              Contact Us
            </Link>
          </div>
        </Carousel.Item>

        {/* third slide */}

        <Carousel.Item>
          <img
            className="d-block img-fluid"
            src="/assets/Banners/1.png"
            alt="First slide"
          />
          <div className="content">
            <h1>Images that tell the story</h1>
          </div>
          <div className="buttons-container">
            <Link to={"/services"} className="service-btn button">
              Services Offered
            </Link>
            <Link to={"/contact"} className="contact-btn button">
              Contact Us
            </Link>
          </div>
        </Carousel.Item>
      </Carousel>

      {/* <div className="content">
        <h1>Capturing the moments that captivate your Heart </h1>
   
      </div>
      <div className="buttons-container">
        <Link to={"/services"} className="service-btn button">Services Offered</Link>
        <Link to={"/contact"}  className="contact-btn button">Contact Us</Link>
      </div> */}
    </div>
  );
}
