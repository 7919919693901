import React from 'react'

export default function Hoverableservicecard({data}) {
    return (
      
            <div className="hover-services-container">
            <img src={data.image} className="img-fluid" alt={"shoot a story"+data.title+"services"}/>
            <section className="content-hover">
              <h3>{data.title} </h3>
            </section>
          </div> 
      
    )
}
