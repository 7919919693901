import React,{useState,useEffect} from "react";
import blogdata from "../../data/blogsdata";
import { useParams} from "react-router";
import { Link } from "react-router-dom";


export default function Singleblog() {

  let {id}=useParams()
  const [currentBlog,setCurrentBlog]=useState({})

  useEffect(() => {
    const filterBlog=blogdata.filter(blog=>blog.id===parseInt(id))
    setCurrentBlog(filterBlog[0])
    console.log(currentBlog)
  },[currentBlog,id])

  return (
    <div className="container">
 
      {/* <Leftrightimage/>
            <Leftrightimage right={true}/>  */}
      <div dangerouslySetInnerHTML={{ __html: currentBlog.content}}></div>

      <section className="more-blogs-container">
               
               <div className="row">
               { blogdata.filter(item=>item.id!== +id).map((item,idx)=>{
                    return(
                      
                        <section className="blogs-card col-11 col-md-6 col-lg-4 mx-auto my-5" key={item.id}>
                              <Link to={`/single-blog/${item.id}`}>
                        <img src={item.thumb} className="img-fluid"  alt=""  />
                        </Link>
                        <h4 className="mt-3">{item.title}</h4>
                        <p >{item.date}</p>
                      
                    </section>
                
                    )
                })}
               </div>
            
               </section>
    </div>
  );
}
