import React from 'react'
import Introcard from '../about/Introcard'
import Title from '../shared/Title'

export default function About() {
    return (
        <div>
         <Title title="about us"/>
         <Introcard/>
        </div>
    )
}
