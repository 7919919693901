import React from "react";

export default function Testamonialcard() {
  return (
    <div className="testamonial-card-container p-md-4 p-4">
      <section className="photo-name">
        <img src="/assets/testimonials.png" alt="" />
        <p>kartik singh</p>
      </section>
      <section className="text mt-4">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac dolor
          placerat sapien curabitur urna a viverra. Egestas et in in iaculis
          venenatis. Metus fermentum sit ullamcorper sem tellus faucibus dolor
          sed. Cras massa morbi nunc feugiat aliquet aliquet orci bibendum.
          Dignissim nisl et, non in facilisis ut enim eget. At urna diam massa,
          condimentum. Cursus velit auctor ut eleifend sit risus, cursus vel. Mi
          leo odio pellentesque dictum est, eleifend. Turpis nec sit molestie
          egestas enim pulvinar. Sed massa ridiculus rhoncus, ullamcorper
          facilisis rhoncus, hendrerit diam maecenas. Nisl diam ipsum.
        </p>
      </section>
    </div>
  );
}
